.home {
  text-align: center;
}

.hero {
  padding: 60px 20px;
  color: white;
  text-align: center;
}

.hero h1, .hero h2 {
  margin: 0 0 20px 0;
}

.hero p {
  margin: 0 0 20px 0;
}

.benefits {
  padding-top: 20px;
  padding-bottom: 120px;
  background-color: #f9f9f9;
  border-radius: 8px;
  text-align: center;
}

.benefits h2 {
  color: #333;
}

.benefits p {
  color: #666;
}

.benefits button a {
  color: white;
  text-decoration: none;
}

.hero-1 {
  background-color: #28a745; /* Ejemplo de color diferente para la primera sección hero */
}

.hero-2 {
  background-color: #dc3545; /* Ejemplo de color diferente para la segunda sección hero */
}
