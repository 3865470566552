.benefit-card {
  position: relative;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 12px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin: 10px;
  padding: 20px;
  width: 200px;
  text-align: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.benefit-card h3 {
  margin: 10px 0;
}

.benefit-description {
  margin: 10px 0 20px;
  flex-grow: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 5; /* Limitar a 5 líneas */
  line-clamp: 5; /* Para compatibilidad con algunos navegadores */
  max-height: calc(1.2em * 5); /* Ajustar si es necesario */
  line-height: 1.2; /* Asegúrate de que esto coincida con la altura de la línea */
}

.benefit-card button {
  background-color: #ff2481;
  color: white;
  border: none;
  border-radius: 12px;
  padding: 10px;
  cursor: pointer;
  align-self: center;
  margin-top: auto;
}

.benefit-card button:hover {
  background-color: #e5186d;
}

.benefit-image {
  width: 100%;
  height: auto;
  border-radius: 12px;
}

.exclusive-label {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: #ff0000;
  color: white;
  padding: 5px;
  border-radius: 3px;
  font-size: 12px;
  font-weight: bold;
}
