.modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background-color: white;
  margin: 20px;
  padding: 20px;
  border: 1px solid #888;
  width: 90%;
  max-width: 800px;
  border-radius: 8px;
  position: relative;
  display: flex; /* Asegura que el contenido interno esté alineado correctamente */
  flex-direction: column; /* Establece la dirección del flujo principal del contenido */
  align-items: center; /* Centra horizontalmente el contenido */
}

.close {
  color: #aaa;
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.modal-body {
  display: flex;
  flex-direction: column;
  align-items: center; /* Centra los elementos verticalmente */
}

.modal-image {
  width: 100%;
  max-width: 400px; /* Ancho máximo de 400px */
  max-height: 400px; /* Altura máxima de 400px */
  height: auto;
  border-radius: 5px;
  margin-bottom: 10px;
  object-fit: cover; /* Mantener proporciones y recortar la imagen si es necesario */
}

.modal-details {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%; /* Asegurar que los detalles ocupen todo el ancho disponible */
  max-width: 400px; /* Ancho máximo para los detalles */
}

.modal-details h2 {
  margin: 0 0 10px 0;
}

.modal-details p {
  margin: 5px 0;
}

.price-details p {
  display: flex;
  align-items: center;
}

.list-price {
  text-decoration: line-through;
  margin-right: 5px;
}

.social-icons {
  display: flex;
  gap: 10px;
  margin: 10px 0 0 0;
}

.icon {
  color: #333;
  transition: color 0.3s ease;
}

.icon:hover {
  color: #007bff;
}

@media (min-width: 768px) {
  .modal-body {
    flex-direction: row;
    align-items: center; /* Centra verticalmente los elementos */
  }

  .modal-image {
    width: 50%;
    max-width: 400px; /* Ancho máximo de 400px */
    max-height: 400px; /* Altura máxima de 400px */
    height: auto; /* Asegurar que la altura se ajuste automáticamente */
    margin-bottom: 0;
    margin-right: 20px;
    object-fit: cover; /* Mantener proporciones y recortar la imagen si es necesario */
  }

  .modal-details {
    width: 50%;
    max-width: none; /* Permitir que los detalles ocupen el espacio restante */
  }
}
