/* src/components/Navbar.css */

.navbar {
  background-color: #ffffff;
  height: 65px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 1000;
}

.navbar-container {
  height: 65px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.navbar-logo .logo {
  height: 100%;
  width: 160px;
}

/* Navegación en desktop */
.navbar-nav {
  display: flex;
}

.nav-list {
  display: flex;
  list-style: none;
  gap: 20px;
}

.nav-list a {
  text-decoration: none;
  color: #3c00ff;
  font-weight: 500;
}

/* Botón Crear Cuenta */
.navbar-cta .cta-button {
  background-color: #3c00ff;
  color: white;
  padding: 8px 16px;
  border-radius: 4px;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.navbar-cta .cta-button:hover {
  background-color: #2800a5;
}

/* Estilos para el Menú Hamburguesa (Visible solo en mobile) */
.menu-toggle {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
}

.hamburger {
  width: 25px;
  height: 3px;
  background-color: #3c00ff;
  display: block;
  margin: 5px 0;
  transition: 0.3s;
}

/* Ocultar menú de navegación en mobile por defecto */
.navbar-nav {
  display: flex;
}

.navbar-nav.active {
  display: block;
}

@media (max-width: 768px) {
  .navbar-container {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .navbar-nav {
    display: none; /* Oculto en mobile por defecto */
    position: absolute;
    top: 60px;
    right: 0;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    border-radius: 8px;
    z-index: 1000;
  }

  .navbar-nav.active {
    display: block; /* Mostrar menú al hacer clic en el ícono */
  }

  .nav-list {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  /* Mostrar el ícono de menú hamburguesa en mobile */
  .menu-toggle {
    display: block;
  }

  /* Alinear el botón Crear Cuenta en mobile */
  .navbar-cta {
    position: absolute;
    right: 20px;
    top: 10px;
  }
}
