/* src/components/Footer.css */

.footer {
  background-color: #1b0068;
  color: #ffffff;
  padding: 20px;
  text-align: left;
  position: relative;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.footer-left,
.footer-right {
  display: flex;
  flex-direction: column;
}

.footer-logo-text {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.footer-logo {
  height: 40px;  /* Mantiene una buena altura para el logo */
  margin-bottom: 10px;
  max-width: 100%;  /* Asegura que no se estire demasiado */
  object-fit: contain; /* Mantiene las proporciones sin recortar */
}

.footer-contact {
  font-size: 14px;
  margin: 10px 0;
}

.footer-social {
  display: flex;
  gap: 10px;
  margin: 10px 0;
}

.footer-social a {
  color: #ffffff;
  font-size: 24px;
  text-decoration: none;
}

.footer-links {
  display: flex;
  gap: 15px;
  margin: 10px 0;
  justify-content: flex-end;
}

.footer-links a {
  color: #00a2ff;
  text-decoration: none;
  font-weight: bold;
}

.footer-app-buttons {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
  justify-content: flex-end;
}

.footer-app-buttons p {
  margin-right: 10px;
}

.footer-app-buttons img {
  width: 130px;
}

.footer-chat-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
}

.footer-chat-button img {
  width: 50px;
  height: 50px;
}

/* Estilos Responsivos */
@media (max-width: 768px) {
  .footer-container {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .footer-left, .footer-right {
    align-items: center;
    margin-bottom: 20px;
  }

  .footer-logo {
    height: 70px;  /* Ajustar el tamaño del logo en pantallas más pequeñas */
  }

  .footer-app-buttons {
    flex-direction: column;
    align-items: center;
  }

  .footer-app-buttons img {
    width: 110px;
  }

  .footer-links {
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 480px) {
  .footer-logo {
    height: 90px;  /* Reducir aún más el tamaño del logo en pantallas muy pequeñas */
  }

  .footer-logo-text h2 {
    font-size: 16px;
  }

  .footer-links a {
    font-size: 14px;
  }

  .footer-app-buttons img {
    width: 90px;
  }
}
