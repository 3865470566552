body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f0f0f0;
  }
  
  .app {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  .content {
    flex: 1;
  }
  
  h1, h2 {
    color: #fff;
  }
  
  a {
    text-decoration: none;
    color: inherit;
  }
  
  button a {
    color: white;
  }
  
  .home {
    text-align: center;
  }
  
  .home header {
    background-color: #3C52FF;
    color: white;
    padding: 20px;
  }
  
  /* .benefit-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  } */
  
  .benefit-card {
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 12px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin: 10px;
    padding: 20px;
    width: 200px;
    text-align: center;
  }

  
  .modal {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
  }
  
  .modal-content {
    background-color: white;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
  }
  
  .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
  