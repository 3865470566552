.benefit-grid-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.benefit-grid {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(4, 1fr);
  max-width: 1000px; /* Adjust this value to fit your design */
}

@media (max-width: 1024px) {
  .benefit-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .benefit-grid {
    grid-template-columns: 1fr;
  }
}
